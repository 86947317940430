<template>
  <v-app id="inspire">
    <v-main>
      <v-container>
        <v-row align="center" justify="center" style="height: 100vh">
          <v-col cols="12" justify="center" style="height: 100vh">
            <div class="justify-center d-flex flex-column align-center" style="height: 100%">
              <img src="../../assets/images/raelic-logo.jpg" width="200">
              <v-form @submit.prevent="login" style="width: 400px;" class="d-flex flex-column align-center">
                <v-card-text>
                  <v-text-field v-model="model.email" label="Email" name="email" prepend-icon="person" type="text"/>
                  <v-text-field v-model="model.password" id="password" label="Password" name="password"
                                prepend-icon="lock"
                                type="password"/>
                </v-card-text>
                <v-card-actions class="mt-0">
                  <div class="text-center">
                    <v-btn class="ma-1 white--text" x-large type="submit" color="primary">Login</v-btn>
                  </div>
                </v-card-actions>
              </v-form>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import axios from "axios";
export default {

  data: () => ({
    loading: false,
    drawer: null,
    model: {
      email: null,
      password: null
    }
  }),

  methods: {
    login() {
      this.loading = true;
      axios.post(process.env.VUE_APP_BASE_API_URL + '/auth/admin', {
        email: this.model.email,
        password: this.model.password,
        rememberMe: false
      }).then(resp => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.accessToken;
        this.$cookies.set('bearerAuth', resp.data.accessToken)
        this.$cookies.set('current_user_id', resp.data.userId)
        this.$store.commit('initIsLoggedIn')
        this.$router.push("/")
      }).catch(e => {
        console.log(e.response);
        this.$toast.error(e.response.data.message)
      }).finally(() => {
        this.loading = false
      })

      // setTimeout(() => {
      //   this.$router.push("/dashboard/dashboard1");
      // }, 1000);
    },
  }
};
</script>
<style scoped>
.title-login {
  text-align: center;

}
</style>
